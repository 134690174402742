<template>
  <form @submit.prevent="handleSubmit">
    <div class="booking-box">

      <div class="booking__header text-center">
        <p class="booking-title">{{contents.title}}</p>
      </div>

      <div class="booking__content d-flex flex-column justify-content-center">
        <div v-html="contents.text" class="text-center"></div>
        <div class="form-floating">
          <input type="text" class="form-control text-center" :class="codeErrorText ? 'is-invalid' : ''" id="verifyCodeInput" placeholder="6-digit code" maxlength="6" v-model="code" @keyup="submitForm" autocomplete="off" autofocus>
          <label for="verifyCodeInput">{{contents.placeholder}}</label>
          <small v-if="codeErrorText" class="text-danger">
            {{ codeErrorText }}
          </small>
        </div>
      </div>

      <div class="booking__button text-center">
        <button type="submit" class="btn btn-primary text-decoration-none mb-3">
          {{contents.btn}}
        </button>
        <a @click="Booking.methods.back(this.$parent)" class="d-block">Vissza</a>
      </div>

    </div>
  </form>
</template>

<script>
import {Validate} from '@/classes/validate'
import {Response} from '@/classes/response'
import Booking from "@/views/Booking";

export default {
  name: 'CodeVerify',
  props:['globalData'],
  components:[
      Booking
  ],
  data(){
    const contents = this.globalData.contents.pages.codeVerify
    const frontendMsg = this.globalData.contents.frontend
    const initData = this.globalData.init
    const location = initData.location
    const service = initData.service
    const reference = initData.reference
    return{
      Booking,
      contents,
      frontendMsg,
      code : '',
      codeErrorText : null,
      location,
      service,
      reference
    }
  },
  methods:{
    submitForm(){
      if(this.code.length >= 6){
        this.handleSubmit()
      }
    },
    handleSubmit() {
      let validate = new Validate()

      this.codeErrorText = validate.validCode(this.code, this.frontendMsg.codeVerify_CodeRequired)

      if (this.codeErrorText) {
        return false;
      }

      let formData = new FormData()
      formData.append('code', this.code)
      formData.append('location', this.location)
      formData.append('service', this.service)
      formData.append('reference', this.reference)

      this.globalData.loader.status = true
      this.$axios.post('/api/v1/user/codeVerify',formData)
          .then((response) => {
            let responseData =  new Response().handle(response)
            this.globalData.errors.push({msg: responseData.msg, type: responseData.msgType})

            if(responseData.data!==null && responseData.data.hasOwnProperty('status')===true){
              this.globalData.init.status = responseData.data.status
            }
            if(responseData.status===true) {
              this.globalData.data.appointments = responseData.data.appointments
              this.globalData.data.availabilities = responseData.data.availabilities
              this.globalData.init.step = 3
            }
          })
          .catch(error => {
            let responseData =  new Response()
            responseData.errorHandle(error.response)
            this.globalData.errors.push({msg: responseData.msg, type: responseData.msgType})
          })
          .then(() => {
            this.globalData.loader.status = false
          });

    }
  }
}
</script>
<style>

</style>
