<template>
  <form @submit.prevent="handleSubmit">
    <div class="booking-box">

      <div class="booking__header text-center">
        <p class="booking-title" v-html="contents.title"></p>
      </div>

      <div class="booking__content">
        <div class="form-floating mb-3">
          <input type="text" class="form-control form-control-sm" id="LoginInputLname" placeholder="Last name" v-model="lastName" maxlength="100" autofocus autocomplete="off">
          <label for="LoginInputLname" class="form-label">{{contents.placeholder_1}}</label>
          <small v-if="lastNameErrorText" class="text-danger">
            {{ lastNameErrorText }}
          </small>
        </div>
        <div class="form-floating mb-3">
          <input type="text" class="form-control form-control-sm" id="LoginInputFname" placeholder="First name" v-model="firstName" maxlength="100" autocomplete="off">
          <label for="LoginInputFname" class="form-label">{{contents.placeholder_2}}</label>
          <small v-if="firstNameErrorText" class="text-danger">
            {{ firstNameErrorText }}
          </small>
        </div>
        <div class="form-floating mb-3">
          <input type="text" class="form-control form-control-sm" :class="emailErrorText ? 'is-invalid' : ''" id="LoginInputEmail" aria-describedby="emailHelp" placeholder="Email address" v-model="email" maxlength="100" autocomplete="off">
          <label for="LoginInputEmail" class="form-label">{{contents.placeholder_3}}</label>
          <!--<div id="emailHelp" class="form-text">Amennyiben ezt adod meg, az e-mail címedre küldjük a belépéshez szükséges kódot.</div>-->
          <small v-if="emailErrorText" class="text-danger">
            {{ emailErrorText }}
          </small>
        </div>
        <div class="form-floating mb-1">
          <input type="text" class="form-control" id="LoginInputPhone" :class="telephoneErrorText ? 'is-invalid' : ''" aria-describedby="phoneHelp" placeholder="Phone number" v-model="telephone" maxlength="30" autocomplete="off">
          <label for="LoginInputPhone" class="form-label">{{contents.placeholder_4}}</label>
          <!--<div id="phoneHelp" class="form-text">Amennyiben ezt adod meg, SMS-ben küldjük a belépéshez szükséges kódot.</div>-->
          <small v-if="telephoneErrorText" class="text-danger">
            {{ telephoneErrorText }}
          </small>
        </div>
      </div>

      <div class="booking__button text-center">
        <button type="submit" class="btn btn-primary text-light text-decoration-none mb-3">
          {{contents.btn}}
        </button>
        <a @click="Booking.methods.back(this.$parent)" class="d-block">Vissza</a>
      </div>

    </div>
  </form>
</template>

<script>
import {Validate} from '@/classes/validate'
import {Response} from '@/classes/response'
import Booking from "@/views/Booking";

export default {
  name: 'Registration',
  props:['globalData'],
  components:[
      Booking
  ],
  data(){
    const contents = this.globalData.contents.pages.registration
    const frontendMsg = this.globalData.contents.frontend
    const initData = this.globalData.init
    const location = initData.location
    const service = initData.service
    const reference = initData.reference
    const email = initData.email
    const telephone = initData.telephone
    return{
      Booking,
      contents,
      frontendMsg,
      lastName : '',
      lastNameErrorText : null,
      firstName : '',
      firstNameErrorText : null,
      email,
      emailErrorText : null,
      telephone,
      telephoneErrorText : null,
      location,
      service,
      reference
    }
  },
  methods:{
    handleSubmit() {
      let validate = new Validate()

      this.lastNameErrorText = validate.emptyField(this.lastName,this.frontendMsg.registration_LastNameRequired)
      this.firstNameErrorText = validate.emptyField(this.firstName,this.frontendMsg.registration_FirstNameRequired)


      this.emailErrorText = validate.emptyField(this.email, this.frontendMsg.registration_EmailRequired)
      if(this.emailErrorText===null){
        this.emailErrorText = validate.email(this.email, this.frontendMsg.registration_EmailRequired)
      }

      this.telephoneErrorText = validate.emptyField(this.telephone, this.frontendMsg.registration_TelephoneRequired)
      if(this.telephoneErrorText===null) {
        this.telephoneErrorText = validate.telephone(this.telephone, this.frontendMsg.registration_TelephoneRequired)
      }

      if (this.lastNameErrorText || this.firstNameErrorText || this.emailErrorText || this.telephoneErrorText) {
        return false;
      }

      let formData = new FormData()
      formData.append('lastName', this.lastName)
      formData.append('firstName', this.firstName)
      formData.append('email', this.email)
      formData.append('telephone', this.telephone)
      formData.append('location', this.location)
      formData.append('service', this.service)
      formData.append('reference', this.reference)

      this.globalData.loader.status = true
      this.$axios.post('/api/v1/user/register',formData)
          .then((response) => {
            let responseData =  new Response().handle(response)
            this.globalData.errors.push({msg: responseData.msg, type: responseData.msgType})

            if(responseData.data!==null && responseData.data.hasOwnProperty('status')===true){
              this.globalData.init.status = responseData.data.status
            }

            if(responseData.status===true) {
              this.globalData.init.step = 2
            }
          })
          .catch(error => {
            let responseData =  new Response()
            responseData.errorHandle(error.response)
            this.globalData.errors.push({msg: responseData.msg, type: responseData.msgType})
          })
          .then(() => {
            this.globalData.loader.status = false
          });

    }
  }
}
</script>
<style>

</style>
