<template>
  <form @submit.prevent="handleSubmit">
    <div class="booking-box">

      <div class="booking__header text-center">
        <p class="booking-title">{{contents.title}}</p>
      </div>

      <div class="booking__content d-flex flex-column justify-content-center">
        <div class="fit-content">
          <select class="form-select form-select-md" :class="serviceErrorText ? 'is-invalid' : ''" aria-label="Select service" v-model="selectedService">
            <option disabled selected value="0">{{contents.placeholder}}</option>
            <option v-for="service in services" v-bind:value="service.id">
              {{service.name}}
            </option>
          </select>
          <small v-if="serviceErrorText" class="text-danger">
            {{ serviceErrorText }}
          </small>
        </div>
      </div>

      <div class="booking__button text-center">
        <button type="submit" class="btn btn-primary text-light text-decoration-none">
          {{contents.btn}}
        </button>
      </div>

    </div>
  </form>
</template>

<script>
import {Validate} from '@/classes/validate'
import {Response} from '@/classes/response'

export default {
  name: 'Services',
  props:['globalData'],
  data(){
    const contents = this.globalData.contents.pages.services
    const frontendMsg = this.globalData.contents.frontend
    const services = this.globalData.data.services
    return{
      contents,
      frontendMsg,
      services,
      selectedService : 0,
      serviceErrorText : null
    }
  },
  methods:{
    handleSubmit() {
      this.serviceErrorText = new Validate().isSelected(this.selectedService, this.frontendMsg.service_Required)
      if (this.serviceErrorText) {
        return false;
      }

      let formData = new FormData()
      formData.append('service', this.selectedService)

      this.globalData.loader.status = true
      this.$axios.post('/api/v1/user/service',formData)
          .then((response) => {
            let responseData =  new Response().handle(response)
            this.globalData.errors.push({msg: responseData.msg, type: responseData.msgType})

            if(responseData.status===true) {
              this.globalData.init.status = responseData.data.status
              this.globalData.data.locations = responseData.data.branches
              this.globalData.init.service = responseData.data.service
              this.globalData.init.serviceName = responseData.data.serviceName
              this.globalData.init.location = responseData.data.location
              this.globalData.init.step = 1
            }
          })
          .catch(error => {
            let responseData =  new Response()
            responseData.errorHandle(error.response)
            this.globalData.errors.push({msg: responseData.msg, type: responseData.msgType})
          })
          .then(() => {
            this.globalData.loader.status = false
          });

    }
  }
}
</script>
<style>

</style>
