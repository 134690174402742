<template>

  <div class="booking-box">

    <div class="booking__header text-center mb-3">
      <p class="booking-title">{{contents.title}}</p>
      <div v-html="contents.text"></div>
    </div>

    <div class="booking__content-wo-button text-center">
      <p class="booking-title">{{serviceName}}</p>
      <p class="booking-title">{{contents.bookedDateTitle}}</p>
      <p>{{thankYouData.date}} {{thankYouData.time}}<br>{{contents.duration}}: {{thankYouData.duration}} {{contents.minute}}</p>
      <p class="booking-title">{{contents.locationTitle}}:</p>
      <p v-html="thankYouData.address"></p>
      <a @click="Booking.methods.back(this.$parent)" class="d-block">Vissza</a>
    </div>

  </div>

</template>

<script>
import Booking from "@/views/Booking";

export default {
  name: 'ThankYou',
  props:['globalData'],
  data(){
    const contents = this.globalData.contents.pages.thankYou
    const initData = this.globalData.init
    const serviceName = initData.serviceName
    const thankYouData = this.globalData.data.thankYou
    return{
      contents,
      serviceName,
      thankYouData,
      Booking
    }
  }
}
</script>
<style>

</style>
