<template>

  <transition name="alert-fade">
    <div @click="close()" class="alert" :class="globalError.type" v-if="globalError.msg" v-show="alertVisible" v-html="globalError.msg">
    </div>
  </transition>

</template>

<script>
export default {
  name: 'Alerts',
  props: ['globalError'],
  computed: {
    alertVisible : function(){
      if(this.globalError.msg!=null){
        setTimeout(() => this.globalError.msg = null, 7000)
        return true;
      }
      return false
    }
  },
  methods:{
    close(){
      this.globalError.msg = null
    }
  }
}
</script>

<style>
  .alert-deck{
    position:absolute;
    width:100%;
    max-width:350px;
    z-index:3900;
    top:0;
    right:0;
  }
  .alert{
    margin:15px;
    padding:.5rem;
    display:flex;
    align-items:center;
    justify-content:space-between;
    border:0;
    border-radius:.23rem;
    opacity:1;
    overflow:hidden;
    min-height:70px;
    background-color: #e0cdb9;
    cursor:pointer;
    color:#ffffff;
    animation:createAlert .25s ease-in-out;
  }
  .alert.success{
    background-color: #fff8f1;
  }
  .alert.error{
    background-color: #fff8f1;
  }

  .alert-fade-enter-active,
  .alert-fade-leave-active {
    animation:createAlert .25s ease-in-out;
  }
  .alert-fade-enter-from,
  .alert-fade-leave-to {
    animation:removeAlert .25s ease-in-out;
  }
  @keyframes createAlert {
    0% {opacity:0;transform:translateY(-50px);}
    100% {opacity:1;transform:translateY(0);}
  }
  @keyframes removeAlert {
    0% {opacity:1;transform:translateY(0);}
    100% {opacity:0;transform:translateY(-50px);}
  }
</style>