<template>

  <div class="booking-box">

    <div v-if="appointments.length>0" class="booked-appointments-wrapper">
      <div><u>{{contents.bookedTitle}}</u></div>
      <template v-for="(appointment) in appointments">
        <div class="d-flex justify-content-between align-items-center mb-1">
          <div><strong>{{appointment.service}}</strong><br>{{appointment.date}} {{appointment.time}} ({{appointment.duration}} {{contents.duration}})</div>
          <div><button v-if="appointment.isCancel===true" class="btn btn-outline-danger btn-sm" @click="cancel(appointment.id)">{{contents.cancel}}</button></div>
        </div>
      </template>
    </div>

    <form @submit.prevent="handleSubmit" class="booking-date-form">
      <div class="booking-date-form__title">
        <div class="booking__header text-center">
          <p class="booking-title">{{selectDateTitle}}</p>
          <div v-html="contents.text"></div>
        </div>
      </div>
      <div class="booking-date-form__dates new-appointment-wrapper mb-3">
        <template v-for="(availability,index) in availabilities">
          <div class="form-check">
            <input class="form-check-input" type="radio" v-model="selectedAvailability" name="flexRadioDate" v-bind:value="availability.id" v-bind:id="'flexRadioDate'+index">
            <label class="form-check-label" v-bind:for="'flexRadioDate'+index">
              {{availability.date}} ({{availability.day}}) {{availability.time}}
            </label>
          </div>
        </template>
        <small v-if="availabilityErrorText" class="text-danger">
          {{ availabilityErrorText }}
        </small>
      </div>
      <div class="booking-date-form__button text-center">
        <button type="submit" class="btn btn-primary text-light text-decoration-none mb-3">
          {{contents.btn}}
        </button>
        <a @click="Booking.methods.back(this.$parent)" class="d-block">Vissza</a>
      </div>
    </form>

  </div>

</template>

<script>
import {Validate} from '@/classes/validate'
import {Response} from '@/classes/response'
import Booking from "@/views/Booking";

export default {
  name: 'SelectDate',
  props:['globalData'],
  components:[
      Booking
  ],
  data(){
    const contents = this.globalData.contents.pages.selectDate
    const frontendMsg = this.globalData.contents.frontend
    const appointments = this.globalData.data.appointments
    const availabilities = this.globalData.data.availabilities
    const initData = this.globalData.init
    const location = initData.location
    const service = initData.service
    const serviceName = initData.serviceName
    const selectDateTitle = frontendMsg.selectDate_Title.replace('{serviceName}',serviceName)
    return{
      Booking,
      contents,
      frontendMsg,
      appointments,
      availabilities,
      selectedAvailability : 0,
      availabilityErrorText : null,
      location,
      service,
      serviceName,
      selectDateTitle
    }
  },
  methods:{
    handleSubmit() {
      let validate = new Validate()

      this.availabilityErrorText = validate.isSelected(this.selectedAvailability, this.frontendMsg.selectDate_Required)
      if (this.availabilityErrorText) {
        return false;
      }

      let formData = new FormData()
      formData.append('availability', this.selectedAvailability)
      formData.append('location', this.location)
      formData.append('service', this.service)

      this.globalData.loader.status = true
      this.$axios.post('/api/v1/user/appointment',formData)
          .then((response) => {
            let responseData =  new Response().handle(response)
            this.globalData.errors.push({msg: responseData.msg, type: responseData.msgType})

            if(responseData.data!==null && responseData.data.hasOwnProperty('status')===true){
              this.globalData.init.status = responseData.data.status
            }

            if(responseData.status===true) {
              this.appointments.push(responseData.data.appointment)
              this.globalData.data.thankYou.address = responseData.data.branch.address
              this.globalData.data.thankYou.date = responseData.data.appointment.date
              this.globalData.data.thankYou.time = responseData.data.appointment.time
              this.globalData.data.thankYou.duration = responseData.data.appointment.duration
              this.globalData.init.step = 4

              gtag('event', 'conversion', {
                'send_to': 'AW-1013184548/QpGGCLqA9cYDEKTwj-MD',
                'value': 1.0,
                'currency': 'HUF'
              });
            }
          })
          .catch(error => {
            let responseData =  new Response()
            responseData.errorHandle(error.response)
            this.globalData.errors.push({msg: responseData.msg, type: responseData.msgType})
          })
          .then(() => {
            this.globalData.loader.status = false
          });

    },
    cancel(appointmentId) {
      let formData = new FormData()
      formData.append('appointment', appointmentId)
      formData.append('service', this.service)

      this.globalData.loader.status = true
      this.$axios.post('/api/v1/user/cancel',formData)
          .then((response) => {
            let responseData =  new Response().handle(response)
            this.globalData.errors.push({msg: responseData.msg, type: responseData.msgType})

            if(responseData.status===true) {
              this.appointments = this.appointments.filter((element => element.id !== appointmentId))
            }
          })
          .catch(error => {
            let response =  new Response()
            response.errorHandle(error.response)
            this.globalData.errors.push({msg: response.msg, type: response.msgType})
          })
          .then(() => {
            this.globalData.loader.status = false
          });

    }
  }
}
</script>
<style>

</style>
