import { createRouter, createWebHistory } from 'vue-router'
import Booking from '../views/Booking.vue'

const routes = [
    {
        path: '/',
        name: 'Booking',
        component: Booking,
        props: true
    }
]

const router = createRouter({
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { top: 0 }
        }
    },
    history: createWebHistory('/'),
    routes
})

export default router
