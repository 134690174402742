<template>
  <form @submit.prevent="handleSubmit">
    <div class="booking-box">

      <div class="booking__header text-center">
        <p class="booking-title">{{contents.title}}</p>
      </div>

      <div class="booking__content d-flex flex-column justify-content-center">
        <div class="fit-content">
          <template v-for="(location,index) in locations">
            <div class="form-check">
              <input class="form-check-input" type="radio" v-model="selectedLocation" name="flexRadioLocation" v-bind:value="location.id" v-bind:id="'flexCheckLocation'+index">
              <label class="form-check-label" v-bind:for="'flexCheckLocation'+index">
                {{location.name}}
              </label>
            </div>
          </template>
          <small v-if="locationErrorText" class="text-danger">
            {{ locationErrorText }}
          </small>
        </div>
      </div>

      <div class="booking__button text-center">
        <button type="submit" class="btn btn-primary text-light text-decoration-none">
          {{contents.btn}}
        </button>
      </div>

    </div>
  </form>
</template>

<script>
import {Validate} from '@/classes/validate'
import {Response} from '@/classes/response'

export default {
  name: 'Locations',
  props:['globalData'],
  data(){
    const contents = this.globalData.contents.pages.location
    const frontendMsg = this.globalData.contents.frontend
    const locations = this.globalData.data.locations
    const initData = this.globalData.init
    const service = initData.service
    return{
      contents,
      frontendMsg,
      locations,
      selectedLocation : 0,
      locationErrorText : null,
      service
    }
  },
  methods:{
    handleSubmit() {
      this.locationErrorText = new Validate().isSelected(this.selectedLocation, this.frontendMsg.location_Required)
      if (this.locationErrorText) {
        return false;
      }

      let formData = new FormData()
      formData.append('location', this.selectedLocation)
      formData.append('service', this.service)

      this.globalData.loader.status = true
      this.$axios.post('/api/v1/user/location',formData)
          .then((response) => {
            let responseData =  new Response().handle(response)
            this.globalData.errors.push({msg: responseData.msg, type: responseData.msgType})

            if(responseData.status===true) {
              this.globalData.init.status = responseData.data.status
              this.globalData.init.location = responseData.data.location
              this.globalData.init.step = 1
            }
          })
          .catch(error => {
            let responseData =  new Response()
            responseData.errorHandle(error.response)
            this.globalData.errors.push({msg: responseData.msg, type: responseData.msgType})
          })
          .then(() => {
            this.globalData.loader.status = false
          });

    }
  }
}
</script>
<style>

</style>
