<template>

  <div class="steps-wrapper d-flex no-gutters align-items-start justify-content-center">
    <div class="col process-step step-1" :class="step===1 ? 'is-active' : ''">
      <div class="step-one-inner"></div>
    </div>
    <div class="col process-step step-2" :class="step===2 ? 'is-active' : ''">
      <div class="step-two-inner"></div>
    </div>
    <div class="col process-step step-3" :class="step===3 ? 'is-active' : ''">
      <div class="step-three-inner"></div>
    </div>
    <div class="col process-step step-4" :class="step===4 ? 'is-active' : ''">
      <div class="step-four-inner"></div>  
    </div>
  </div>

</template>

<script>
export default {
  name: 'Step',
  props:['globalData'],
  data(){
    return{

    }
  },
  computed:{
    step(){
      return this.globalData.init.step
    }
  }
}
</script>
<style>

</style>
