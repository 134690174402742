<template>
  <footer>
  </footer>
</template>

<script>

export default{
  name:'Footer',
  props:['globalData']
}
</script>

<style>
</style>