<template>
  <header>
  </header>
</template>

<script>

export default{
  name:'Header',
  props:['globalData']
}
</script>

<style>
</style>