<template>
  <form @submit.prevent="handleSubmit">
    <div class="booking-box">

      <div class="booking__header text-center">
        <p class="booking-title">{{contents.title}}</p>
      </div>

      <div class="booking__content d-flex flex-column justify-content-center">
        <div>
          <div class="form-floating mb-1">
            <input type="text" class="form-control form-control-sm" :class="emailErrorText ? 'is-invalid' : ''" id="LoginInputEmail" aria-describedby="emailHelp" placeholder="Email address" v-model="email">
            <label for="LoginInputEmail" class="form-label">{{contents.placeholder_1}}</label>
            <!--<div id="emailHelp" class="form-text">Amennyiben ezt adod meg, az e-mail címedre küldjük a belépéshez szükséges kódot.</div>-->
            <small v-if="emailErrorText" class="text-danger">
              {{ emailErrorText }}
            </small>
          </div>
          <div class="mb-1 text-center">
            <span class="d-inline-block mx-auto">{{contents.separator}}</span>
          </div>
          <div class="form-floating mb-3">
            <input type="text" class="form-control" :class="telephoneErrorText ? 'is-invalid' : ''" id="LoginInputPhone" aria-describedby="phoneHelp" placeholder="Phone number" v-model="telephone">
            <label for="LoginInputPhone" class="form-label">{{contents.placeholder_2}}</label>
            <!--<div id="phoneHelp" class="form-text">Amennyiben ezt adod meg, SMS-ben küldjük a belépéshez szükséges kódot.</div>-->
            <small v-if="telephoneErrorText" class="text-danger">
              {{ telephoneErrorText }}
            </small>
          </div>
          <div class="form-check">
            <input type="checkbox" class="form-check-input" :class="policyErrorText ? 'is-invalid' : ''" id="LoginCheckTerms" v-model="policy">
            <label class="form-check-label" for="LoginCheckTerms"><span v-html="contents.policy"></span></label>
            <br>
            <small v-if="policyErrorText" class="text-danger">
              {{ policyErrorText }}
            </small>
          </div>
        </div>
      </div>

      <div class="booking__button text-center">
        <button type="submit" class="btn btn-primary text-light text-decoration-none">
          {{contents.btn}}
        </button>
      </div>

    </div>
  </form>
</template>

<script>
import {Validate} from '@/classes/validate'
import {Response} from '@/classes/response'

export default {
  name: 'Identify',
  props:['globalData'],
  data(){
    const contents = this.globalData.contents.pages.identify
    const frontendMsg = this.globalData.contents.frontend
    const initData = this.globalData.init
    const location = initData.location
    const service = initData.service
    return{
      contents,
      frontendMsg,
      email : '',
      emailErrorText : null,
      telephone : '',
      telephoneErrorText : null,
      policy : false,
      policyErrorText : null,
      service,
      location
    }
  },
  methods:{
    handleSubmit() {
      let validate = new Validate()

      this.emailErrorText = validate.email(this.email, this.frontendMsg.userVerify_EmailRequired)
      this.telephoneErrorText = validate.telephone(this.telephone, this.frontendMsg.userVerify_TelephoneRequired)
      this.policyErrorText = validate.checkboxChecked(this.policy, this.frontendMsg.userVerify_Policy1Required);

      if(this.email.length === 0 && this.telephone.length === 0){
        this.emailErrorText = this.frontendMsg.userVerify_TelephoneOrEmailRequired
      }


      if (this.emailErrorText || this.telephoneErrorText || this.policyErrorText) {
        return false;
      }

      let formData = new FormData()
      formData.append('email', this.email)
      formData.append('telephone', this.telephone)
      formData.append('policy_1', this.policy)
      formData.append('service', this.service)
      formData.append('location', this.location)

      this.globalData.loader.status = true
      this.$axios.post('/api/v1/user/identify',formData)
          .then((response) => {
            let responseData =  new Response().handle(response)
            this.globalData.errors.push({msg: responseData.msg, type: responseData.msgType})

            if(responseData.status===true) {
              this.globalData.init.status = responseData.data.status
              this.globalData.init.email = responseData.data.email
              this.globalData.init.telephone = responseData.data.telephone
            }
          })
          .catch(error => {
            let responseData =  new Response()
            responseData.errorHandle(error.response)
            this.globalData.errors.push({msg: responseData.msg, type: responseData.msgType})
          })
          .then(() => {
            this.globalData.loader.status = false
          });

    }
  }
}
</script>
<style>

</style>
