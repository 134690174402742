<template>
  <Head :globalData="globalData"></Head>
  <Header :globalData="globalData"/>
  <div class="booking-container">
    <Loader :globalData="globalData"/>
    <div class="alert-deck">
      <Alerts :globalError="error" v-for="error in globalData.errors"/>
    </div>
    <router-view :globalData="globalData"/>
  </div>
  <Footer :globalData="globalData"></Footer>
</template>

<script>
import { reactive} from 'vue'
import Loader from "./components/Loader"
import Alerts from "./components/Alerts"
import Head from './components/Head'
import Header from './components/Header'
import Footer from './components/Footer'

export default {
  name: 'App',
  props: ['serverData'],
  components: {
   Loader,Alerts,Head,Header,Footer
  },
  data(){
    const globalData = reactive(this.serverData)
    console.log(this.serverData)
    globalData.loader.status = false

    return {
      globalData
    }
  }
}
</script>

<style>

</style>
