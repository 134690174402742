export class Validate{
    constructor(){
    }

    isSelected(value, errorText){
        if(value === 0 || value.length === 0){
            return errorText
        }
        return null
    }

    emptyField(value, errorText){
        if(value === null || value.length === 0){
            return errorText
        }
        return null
    }
    minLength(value, minLength, errorText){
        if(value.length < minLength){
            return errorText
        }
        return null
    }
    maxLength(value, maxLength, errorText){
        if(value.length > maxLength){
            return errorText
        }
        return null
    }

    email(value, errorText){
        if(value==null){
            return null
        }
        if (value.length>0 && value.search(new RegExp("^[\.\+_a-z0-9-]+@([0-9a-z][0-9a-z-\.]*[0-9a-z][\.])+[a-z][a-z][mtgvuzfrlpo]?[oepa]?$", "gi")) < 0) {
            return errorText
        }
        return null
    }

    telephone(value, errorText){
        if(value===null){
            return null
        }
        if(value.length>0 && value.length < 10){
            return errorText
        }
        return null
    }

    checkboxChecked(value, errorText){
        if(value===false){
            return errorText
        }
        return null
    }

    validCode(value, errorText){
        if(value.length < 6){
            return errorText
        }
        return null
    }

}