<template>
  <Step :globalData="globalData"></Step>
  <Services v-if="status==='services'" :globalData="globalData"></Services>
  <Locations v-else-if="status==='location'" :globalData="globalData"></Locations>
  <Identify v-else-if="status==='identify'" :globalData="globalData"></Identify>
  <Registration v-else-if="status==='registration'" :globalData="globalData"></Registration>
  <CodeVerify v-else-if="status==='codeVerify'" :globalData="globalData"></CodeVerify>
  <SelectDate v-else-if="status==='selectDate'" :globalData="globalData"></SelectDate>
  <ThankYou v-else-if="status==='thankYou'" :globalData="globalData"></ThankYou>
</template>

<script>
import Services from '../components/Services.vue'
import Locations from '../components/Locations.vue'
import Identify from '../components/Identify.vue'
import Registration from '../components/Registration.vue'
import CodeVerify from '../components/CodeVerify.vue'
import SelectDate from "../components/SelectDate";
import ThankYou from "../components/ThankYou";
import Step from "../components/Step";
import {Response} from "@/classes/response";

export default {
  name: 'Booking',
  props:['globalData'],
  components: {
    Services,Locations,Identify,Registration,CodeVerify,SelectDate,ThankYou,Step
  },
  computed:{
    status(){
      return this.globalData.init.status
    },
    service(){
      return this.globalData.init.service
    }
  },
  methods: {
    back(e) {
      let formData = new FormData()
      formData.append('service', e.service)
      formData.append('status', e.status)

      e.globalData.loader.status = true
      e.$axios.post('/api/v1/user/back', formData)
          .then((response) => {
            let responseData = new Response().handle(response)
            e.globalData.errors.push({msg: responseData.msg, type: responseData.msgType})

            if (responseData.status === true) {
              e.globalData.init.status = responseData.data.status
              e.globalData.init.step = responseData.data.step
            }
          })
          .catch(error => {
            let response = new Response()
            response.errorHandle(error.response)
            e.globalData.errors.push({msg: response.msg, type: response.msgType})
          })
          .then(() => {
            e.globalData.loader.status = false
          });
    }
  }
}
</script>
<style>

</style>
